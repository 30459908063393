import React, { useEffect, useState, useRef } from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, orderBy, limit, getDocs } from 'firebase/firestore';

const firebaseConfig = {
      apiKey: "AIzaSyC7fT14E1NUkC8ccL-Zp84oEzQhxrx9LjM",
      authDomain: "musa-flow.firebaseapp.com",
      projectId: "musa-flow",
      storageBucket: "musa-flow.appspot.com",
      messagingSenderId: "165652957911",
      appId: "1:165652957911:web:548d025235fc78577d3e92",
      measurementId: "G-PVBSQS6XDV"
    };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const BrandPortal = () => {
    const [latestDoc, setLatestDoc] = useState(null);
    const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchLatestDoc = async () => {
      const q = query(collection(db, 'brandMetaDB'),
      orderBy('timestamp', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setLatestDoc(doc.data());
        setMessages([{ role: "system", content: `Your entire personality, world outlook, thoughts, and answers will be done by taking internalizing this brand, you will never break character: ${JSON.stringify(doc.data())}` }]);
      });
    };

    fetchLatestDoc();
  }, [db]);
        
    const pstyles = React.useMemo(() => {
    if (!latestDoc) return {};

    const fontSize = '1rem';
    const fontFamily = latestDoc["Main-Font"].split(';')[0];

    return {
      fontSize,
      fontFamily,
    };
  }, [latestDoc]);
    
    const heading = React.useMemo(() => {
    if (!latestDoc) return {};

    const fontFamily = latestDoc["Primary-Font-Heading"].split(';')[0];
    const color = latestDoc["Primary-Font-Heading-Color"].split(';')[0];

    return {
      fontFamily,
      color,
    };
    }, [latestDoc]);
    
    const buttonstyles = React.useMemo(() => {
    if (!latestDoc) return {};

    const fontSize = '1rem'; 
    const padding = '1rem';
    const textAlign = 'center'; 
    const textDecoration = 'none'; 
    const display = 'inline-block';
    const cursor = 'pointer';
    const fontFamily = latestDoc["Main-Font"].split(';')[0];
        
    return {
      fontSize,
      padding,
      textAlign,
      textDecoration,
      display,
      fontFamily,
      cursor,
    };
    }, [latestDoc]);

return (
<main className="brand-portal grid cols-4">

      <section className="intro">
        <div className="holder-of-things">
            <h1>Brand Profile</h1>
        </div>
        </section>
        <div className="jumpto">
        <span>Jump to</span>
            <ul>
                <li><a href="#core">Core</a></li>
                <li><a href="#target">Target</a></li>
                <li><a href="#logo">Logo</a></li>
                <li><a href="#colors">Colors</a></li>
                <li><a href="#fonts">Fonts</a></li>
                <li><a href="#imagery">Imagery</a></li>
                <li><a href="#voice">Voice</a></li>
                <li><a href="#digital">Digital Guidelines</a></li>
                <li><a href="#sns">SNS</a></li>
                <li><a href="#email-marketing">Email Marketing</a></li>
                <li><a href="#ads">Digital advertising</a></li>
                <li><a href="#app">Mobile and App</a></li>
                <li><a href="#seo">SEO and Content</a></li>
                <li><a href="#trademark">Trademarks</a></li>
            </ul>
        </div>
      <section className="content">
    <div className="component" id="core">
        <h2>Brand core</h2>
        <div className="division">
            <h3>Purpose</h3>
            {latestDoc ? (
              <p id="company-purpose">
                {latestDoc["Brand-Purpose"]}
              </p>
            ) : (
              <p>Loading...</p>
            )}
        </div>
        <div className="division">
            <h3>Values</h3>
            <ul className="value-containers containers" id="brand-values">
                {latestDoc ? (
              <p id="company-mission">
                {latestDoc["Brand-Values"]}
              </p>
            ) : (
              <p>Loading...</p>
            )}
            </ul>
        </div>
        <div className="division">
            <h3>Mission</h3>
            {latestDoc ? (
              <p id="company-mission">
                {latestDoc["Brand-Mission"]}
              </p>
            ) : (
              <p>Loading...</p>
            )}
        </div>
        <div className="division">
            <h3>Vision</h3>
            {latestDoc ? (
              <p id="company-mission">
                {latestDoc["Brand-Vision"]}
              </p>
            ) : (
              <p>Loading...</p>
            )}
        </div>
        <div className="division">
            <h3>Brand Story</h3>
            {latestDoc ? (
              <p id="story">
                {latestDoc["Brand-Story"]}
              </p>
            ) : (
              <p>Loading...</p>
            )}
        </div>
        <div className="division">
            <h3>History</h3>
            {latestDoc ? (
              <p id="history">
                {latestDoc["Brand-History"]}
              </p>
            ) : (
              <p>Loading...</p>
            )}
        </div>
        <div className="division">
            <h3>Positioning</h3>
            {latestDoc ? (
              <p id="brand-positioning">
                {latestDoc["Brand-Positioning"]}
              </p>
            ) : (
              <p>Loading...</p>
            )}
        </div>
   </div>
    <div className="component" id="target">
        <h2>Target Audience*</h2>
        <div className="division">
            <h3>Target Customer</h3>
            <ul className="target-containers containers" id="target-customer">
                {latestDoc ? (
                      <p id="target-audiences">
                        {latestDoc["Target-Audiences"]}
                      </p>
                    ) : (
                      <p>Loading...</p>
                )}
            </ul>
        </div>
        {/*<div className="division">
            <h3>Target Candidate</h3>
            <ul className="target-containers containers" id="target-candidate">
                <li id="brand-target-candidate-01"></li>
                <li id="brand-target-candidate-02"></li>
                <li id="brand-target-candidate-03"></li>
            </ul>
        </div>
        <div className="division">
            <h3>Target Other</h3>
            <ul className="target-containers containers" id="target-other">
                <li id="brand-target-other-01"></li>
                <li id="brand-target-other-02"></li>
                <li id="brand-target-other-03"></li>
            </ul>
        </div>*/}
    </div>
    <div className="component" id="logo">
        <h2>Logo</h2>
        <div className="division">
        <h3>Primary Logo</h3>
        <ul className="logo-containers containers" id="primary-logo">
            <li id="brand-primary-logo-01"></li>
            <li id="brand-primary-logo-02"></li>
            <li id="brand-primary-logo-03"></li>
        </ul>
        </div>
        {/*<div className="division">
            <h3>Secondary Logo</h3>
            <ul className="logo-containers containers" id="secondary-logo">
                <li id="brand-secondary-logo-01"></li>
                <li id="brand-secondary-logo-02"></li>
                <li id="brand-secondary-logo-03"></li>
            </ul>
        </div>*/}
        <div className="division">
            <h3>Logo Usage</h3>
            <ul className="logo-usage-containers containers" id="logo-usage">
                <li id="brand-logo-clearspace-01"></li>
                <li id="brand-logo-min-size"></li>
                <li id="brand-logo-usage">Do not:
                    <ul className="logo-do-not">
                        <li>Squish, Squeeze, Distort</li>
                        <li>Change font</li>
                        <li>etc.</li>
                    </ul>
                </li>
            </ul>
        </div>
      </div>
    <div className="component" id="colors">
        <h2>Colors</h2>
        <div className="division">
            <h3>Primary Colors</h3>
            <ul className="color-containers containers" id="primary-color">
                <li>{latestDoc["Color-Primary-Name"].split(';')[0]}
                    <ul>
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-HEX"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-RGB"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-CMYK"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-Pantone"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                    </ul>
                </li>
                <li>{latestDoc["Color-Primary-Name"].split(';')[1]}
                    <ul>
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-HEX"].split(';')[1]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-RGB"].split(';')[1]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-CMYK"].split(';')[1]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-Pantone"].split(';')[1]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                    </ul>
                </li>
                <li>{latestDoc["Color-Primary-Name"].split(';')[2]}
                    <ul>
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-HEX"].split(';')[2]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-RGB"].split(';')[2]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-CMYK"].split(';')[2]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Primary-Pantone"].split(';')[2]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                    </ul>
                </li>
            </ul>
        </div>
        <div className="division">
            <h3>Secondary Colors</h3>
            <ul className="color-containers containers" id="secondary-color">
                <li>{latestDoc["Color-Secondary-Name"].split(';')[0]}
                    <ul>
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Secondary-HEX"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Secondary-RGB"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Secondary-CMYK"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Secondary-Pantone"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                    </ul>
                </li>
            </ul>
        </div>
        <div className="division">
            <h3>Tertiary Colors</h3>
            <ul className="color-containers containers" id="secondary-color">
                <li>{latestDoc["Color-Tertiary-Name"].split(';')[0]}
                    <ul>
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Tertiary-HEX"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Tertiary-RGB"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Tertiary-CMYK"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                        {latestDoc ? (
                          <li>
                            {latestDoc["Color-Tertiary-Pantone/PMS"].split(';')[0]}
                          </li>
                        ) : (
                          <li>Loading...</li>
                        )}
                    </ul>
                </li>
            </ul>
        </div>
        <div className="division">
            <h3>Color Usage</h3>
            <ul className="color-usage-containers containers" id="color-usage">
                <li id="color-logo-usage"></li>
            </ul>
        </div>
    </div>
    <div className="component" id="fonts">
        <h2>Fonts</h2>
        <div className="division">
            <h3>Primary Fonts</h3>
            <ul className="font-containers containers" id="primary-font">
                {latestDoc ? (
                <li>
                    {latestDoc["Main-Font"].split(';')[0]}
                    </li>
                    ) : (
                    <li>Loading...</li>
                )}
            </ul>
        </div>
        
    {/*<div className="division">
        <h3>Secondary Fonts</h3>
        <ul className="font-containers containers" id="secondary-font">
            <li id="brand-secondary-font-01"></li>
            <li id="brand-secondary-font-02"></li>
            <li id="brand-secondary-font-03"></li>
        </ul>
        </div>
        <div className="division">
            <h3>Font usage</h3>
            <ul className="font-usage-containers containers" id="font-usage">
                <li id="font-logo-usage"></li>
            </ul>
        </div>*/}
    </div>
    <div className="component" id="imagery">
        <h2>Imagery</h2>
        {/*<div className="division">
            <h3>Category 1</h3>
            <ul className="imagery-containers containers" id="primary-imagery">
                <li id="brand-primary-imagery-01"></li>
                <li id="brand-primary-imagery-02"></li>
                <li id="brand-primary-imagery-03"></li>
            </ul>
        </div>
        <div className="division">
            <h3>Category 2</h3>
            <ul className="imagery-containers containers" id="secondary-imagery">
                <li id="brand-secondary-imagery-01"></li>
                <li id="brand-secondary-imagery-02"></li>
                <li id="brand-secondary-imagery-03"></li>
            </ul>
        </div>*/}
        <div className="division">
            <h3>Graphic elements</h3>
                {latestDoc ? (
                <p>
                    {latestDoc["Graphic-Elements"]}
                </p>
                ) : (
                <p>Loading...</p>
                )}
        </div>
        <div className="division">
            <h3>Illustrations</h3>
                {latestDoc ? (
                <p>
                    {latestDoc["Illustrations"]}
                </p>
                ) : (
                <p>Loading...</p>
                )}
         </div>
        <div className="division">
            <h3>Illustration Styles</h3>
                {latestDoc ? (
                <p>
                    {latestDoc["Images-Illustration-Styles"]}
                </p>
                ) : (
                <p>Loading...</p>
                )}
         </div>
        <div className="division">
            <h3>Photo Styles</h3>
                {latestDoc ? (
                <p>
                    {latestDoc["Images-Photograph-Styles"]}
                </p>
                ) : (
                <p>Loading...</p>
                )}
         </div>
        <div className="division">
            <h3>Pictogram Styles</h3>
                {latestDoc ? (
                <p>
                    {latestDoc["Images-Pictogram-Styles"]}
                </p>
                ) : (
                <p>Loading...</p>
                )}
        </div>
    </div>
    <div className="component" id="voice">
        <h2>Brand Voice</h2>
        <div className="division">
            <h3>Tone</h3>
            {latestDoc ? (
              <p id="company-mission">
                {latestDoc["Brand-Tone-of-Voice"]}
              </p>
            ) : (
              <p>Loading...</p>
            )}
        </div>
        {/*<div className="division">
            <h3>Vocabulary</h3>
            <ul className="vocabulary-containers containers" id="brand-vocabulary">
                <li id="brand-primary-vocabulary-01"></li>
                <li id="brand-primary-vocabulary-02"></li>
                <li id="brand-primary-vocabulary-03"></li>
            </ul>
        </div>*/}
        <div className="division">
            <h3>Emotions</h3>
            {latestDoc ? (
              <p id="company-emotions">
                {latestDoc["Brand-Emotions"]}
              </p>
            ) : (
              <p>Loading...</p>
            )}
        </div>
    </div>
    <div className="component" id="digital">
        <h2>Digital Guidelines</h2>
        <div className="division">
            <h3>Layout and Grid System:</h3>
            <div className="grid cols-6">
                <div>Item</div>
                <div>Item</div>
                <div>Item</div>
                <div>Item</div>
                <div>Item</div>
                <div>Item</div>
                <div>Item</div>
                <div>Item</div>
                <div>Item</div>
                <div>Item</div>
                <div>Item</div>
                <div>Item</div>
            </div>
        </div>
        <div className="division">
            <h3>Web specific styles</h3>
            <div className="digital-containers containers" id="digital-guidelines">
                <div id="brand-digital-01">
                    <h1 style={{...heading, fontSize: '1rem'}}>Heading 1</h1>
                </div>
                <div id="brand-digital-02">
                    <h2 style={{...heading, fontSize: '1rem'}}>Heading 2</h2>
                </div>
                <div id="brand-digital-03">
                    <h3 style={{...heading, fontSize: '1rem'}}>Heading 3</h3>
                </div>
                <div id="brand-digital-04">
                    <h4 style={{...heading, fontSize: '1rem'}}>Heading 4</h4>
                </div>
                <div id="brand-digital-05">
                    <h5 style={{...heading, fontSize: '1rem'}}>Heading 5</h5>
                </div>
                <div id="brand-digital-06">
                    <h6 style={{...heading, fontSize: '1rem'}}>Heading 6</h6>
                </div>
        
                <div id="brand-digital-06">
                    <p style={pstyles}>Paragraph</p>
                    <p style={{...pstyles, fontWeight:'bold'}}>Bold Paragraph</p>
                    <p style={{...pstyles, fontStyle:'italic'}}>Italic Paragraph</p>
                    <p style={{...pstyles, textDecoration:'underlined'}}>Underlined Paragraph</p>
                    <p style={{...pstyles, textDecoration:'line-through'}}>Strikethrough Paragraph</p>
                </div>
        
                <div id="brand-digital-07">
                    <button 
                        style={{...buttonstyles, 
                                backgroundColor: latestDoc["Color-Primary-HEX"].split(';')[0], 
                                border: '', 
                                borderColor: '',
                                borderWeight: '', 
                                color: ''}} 
                        className="primary-button">
                        Button
                    </button>
                </div>
                <div id="brand-digital-08"> 
                    <button 
                        style={{...buttonstyles, 
                                backgroundColor: latestDoc["Color-Primary-HEX"].split(';')[1], 
                                border: '', 
                                borderColor: '',
                                borderWeight: '', 
                                color: ''}} 
                        className="secondary-button">
                        Button
                    </button>
                </div> 
                <div id="brand-digital-09"> 
                    <button 
                        style={{...buttonstyles, 
                                backgroundColor: '', 
                                border: '', 
                                borderColor: '',
                                borderWeight: '', 
                                color: ''}} 
                        className="other-button">
                        Button
                    </button>
                </div>
                <div id="brand-digital-10">
                    <a href="#" style={{fontSize: '1rem'}} className="primary-link">Link</a>
                    </div>
                <div id="brand-digital-11">
                    <a href="#" style={{fontSize: '1rem'}} className="secondary-link">Link</a>
                </div>
                <div id="brand-digital-12">
                    <a href="#" style={{fontSize: '1rem'}} className="other-link">Link</a>
                </div>
        
                <div id="brand-digital-13">
                    <ol className="ordered-list">
                        <li>Ordered list item</li>
                        <li>Ordered list item
                            <ul className="unordered-list">
                                <li>Ordered list item</li>
                                <li>Ordered list item</li>
                                <li>Ordered list item</li>
                            </ul>
                        </li>
                        <li>Ordered list item
                            <ol className="ordered-list">
                                <li>Ordered list item</li>
                                <li>Ordered list item</li>
                                <li>Ordered list item</li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div id="brand-digital-14">
                    <ul className="unordered-list">
                        <li>Unordered list item</li>
                        <li>Unordered list item
                            <ul className="unordered-list">
                                <li>Ordered list item</li>
                                <li>Ordered list item</li>
                                <li>Ordered list item</li>
                            </ul>
                        </li>
                        <li>Unordered list item
                            <ol className="ordered-list">
                                <li>Ordered list item</li>
                                <li>Ordered list item</li>
                                <li>Ordered list item</li>
                            </ol>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="division">
            <h3>Web specific OTHER</h3>
            <div className="digital-containers containers" id="digital-guidelines">
                <div style={{margin: '1rem'}} className="margin">Default margin</div>
                <div style={{padding: '1rem'}} className="padding">Default padding</div>
                <div style={{border: '1px solid'}} className="border">Default border</div>
                <div style={{borderBottom: '1px solid'}} className="border-bottom">Default border bottom</div>
                <div style={{borderTop: '1px solid'}} className="border-top">Default border top</div>
                <div style={{borderLeft: '1px solid'}} className="border-left">Default border left</div>
                <div style={{borderRight: '1px solid'}} className="border-right">Default border right</div>
            </div>
        </div>
   </div>
    <div className="component" id="sns">
        <h2>SNS Considerations</h2>
        <div className="division">
            <h3>STUFF HERE</h3>
            <ul className="legal-containers containers" id="legal-guidelines">
                <li id="brand-legal-01"></li>
                <li id="brand-legal-02"></li>
                <li id="brand-legal-03"></li>
            </ul>
        </div>
   </div>
    <div className="component" id="email-marketing">
        <h2>Email Marketing Considerations</h2>
        <div className="division">
            <h3>STUFF HERE</h3>
            <ul className="legal-containers containers" id="legal-guidelines">
                <li id="brand-legal-01"></li>
                <li id="brand-legal-02"></li>
                <li id="brand-legal-03"></li>
            </ul>
        </div>
    </div>
    <div className="component" id="ads">
        <h2>Digital Advertising Considerations</h2>
        <div className="division">
            <h3>STUFF HERE</h3>
            <ul className="legal-containers containers" id="legal-guidelines">
                <li id="brand-legal-01"></li>
                <li id="brand-legal-02"></li>
                <li id="brand-legal-03"></li>
            </ul>
        </div>
    </div>
    <div className="component" id="app">
        <h2>Mobile and App Considerations</h2>
            <div className="division">
            <h3>STUFF HERE</h3>
            <ul className="legal-containers containers" id="legal-guidelines">
                <li id="brand-legal-01"></li>
                <li id="brand-legal-02"></li>
                <li id="brand-legal-03"></li>
            </ul>
        </div>
    </div>
    <div className="component" id="seo">
        <h2>SEO and Content Considerations</h2>
        <div className="division">
            <h3>Key Messages</h3>
            <div className="content-containers containers" id="content-guidelines">
                {latestDoc ? (
                      <p id="key-messages">
                        {latestDoc["Content-Key-Messages"]}
                      </p>
                    ) : (
                      <p>Loading...</p>
                )}
            </div>
        </div>
        <div className="division">
            <h3>Content Writing Guidelines</h3>
            <div className="content-containers containers" id="content-weiting-guidelines">
                {latestDoc ? (
                      <p id="writing-guidelines">
                        {latestDoc["Content-Writing-Rules"]}
                      </p>
                    ) : (
                      <p>Loading...</p>
                )}
            </div>
        </div>
    </div>
    <div className="component" id="trademark"> 
        <h2>Trademark Considerations</h2>
        <div className="division">
            <h3>STUFF HERE</h3>
            <ul className="trademark-containers containers" id="trademark-guidelines">
                <li id="brand-legal-01"></li>
                <li id="brand-legal-02"></li>
                <li id="brand-legal-03"></li>
            </ul>
        </div>
    </div>
    </section>
    <section className="sidebar">
         <p className="last-update">Last Updated <span>DD/MM/YYYY</span></p>
    </section>
</main>
    )
}

export default BrandPortal;
