import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import './app.css'
import Home from './views/home'
import NotFound from './views/not-found'
import Talk2Brand from './views/talk2brand'
import BrandPortal from './views/brandportal'
import Login from './components/Login'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Talk2Brand} exact path="/talk2brand" />
        <Route component={BrandPortal} exact path="/brandportal" />
        <Route component={Login} exact path="/login" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
