import React, { useEffect, useState, useRef } from 'react';
import { initializeApp } from "firebase/app";
import OpenAI from 'openai';
import { getFirestore, collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import pdfToText from 'react-pdftotext';

const firebaseConfig = {
  apiKey: "AIzaSyC7fT14E1NUkC8ccL-Zp84oEzQhxrx9LjM",
  authDomain: "musa-flow.firebaseapp.com",
  projectId: "musa-flow",
  storageBucket: "musa-flow.appspot.com",
  messagingSenderId: "165652957911",
  appId: "1:165652957911:web:548d025235fc78577d3e92",
  measurementId: "G-PVBSQS6XDV"
};

const openai = new OpenAI({
  apiKey: "sk-proj-i8B0M8xQh3fXfllQLL4UT3BlbkFJqLVV93oW2t9nlL5BjSxi", dangerouslyAllowBrowser: true
});


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage();

const Talk2Brand = () => {
  const [latestDoc, setLatestDoc] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [pdfText, setPdfText] = useState('');
  const [brandAssets, setBrandAssets] = useState([]);
  const dummy = useRef(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchLatestDoc = async () => {
      const q = query(collection(db, 'brandMetaDB'), orderBy('timestamp', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setLatestDoc(doc.data());
        setMessages([{ role: "system", content: `Your entire personality, world outlook, thoughts, and answers will be done by taking internalizing this brand, you will never break character: ${JSON.stringify(doc.data())}` }]);
      });
    };

    const fetchBrandAssets = async () => {
      const q = query(collection(db, 'brandAssets'), orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const assets = [];

      for (const doc of querySnapshot.docs) {
        const data = doc.data();
        const assetName = data.assetName;
        const brandName = data.brandName;
        const folderPath = `${brandName}/${assetName}/`;

        try {
          const listRef = ref(storage, folderPath);
          const res = await listAll(listRef);
          for (const itemRef of res.items) {
            const url = await getDownloadURL(itemRef);
            assets.push({ ...data, url, fileName: itemRef.name });
          }
        } catch (error) {
          console.error("Error fetching download URLs:", error);
        }
      }

      setBrandAssets(assets);
    };

    fetchLatestDoc();
    fetchBrandAssets();
  }, [refresh]);

  console.log('Brand Assets: ', brandAssets)

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newMessages = [
      ...messages,
      { role: "user", content: userInput }
    ];
    setMessages(newMessages);
    setUserInput('');
    setLoading(true);

    try {
      const response = await openai.chat.completions.create({
        model: "gpt-3.5-turbo",
        temperature: 0.4,
        messages: newMessages
      });

      console.log('This is response', response);

      const assistantMessage = response.choices[0].message;

      setMessages([
        ...newMessages,
        { role: "assistant", content: assistantMessage.content }
      ]);
    } catch (error) {
      console.error("Error fetching response from OpenAI:", error);
    } finally {
      setLoading(false);
    }
  };


  const extractText = async (event) => {
    console.log('Loading..');
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      try {
        const text = await pdfToText(file);
        setPdfText(text);

        // Make the API request with the extracted text
        const uploadResponse = await fetch('https://flow-server-uc4t2b2ega-ue.a.run.app/upload', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userInput: text })
        });
        const uploadData = await uploadResponse.json();
        console.log('Success:', uploadData);

        // After successful upload, send the same data to the generate-images endpoint
        const generateResponse = await fetch('https://flow-server-uc4t2b2ega-ue.a.run.app/generate-images', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ brandInfo: text })
        });
        const generateData = await generateResponse.json();
        console.log('Generate Success:', generateData);
        setRefresh(!refresh)

      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      alert("Please upload a PDF file.");
    }
    console.log('This is the PDF Text:', pdfText);
    console.log('This is latestDoc:', latestDoc);    
  };
    
    useEffect(() => {
      dummy.current.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const bannerAsset = brandAssets.find(asset => asset.assetName === "banner");
    const squarePortrait1 = brandAssets.find(asset => asset.assetName === "square portrait 1");
    const squarePortrait2 = brandAssets.find(asset => asset.assetName === "square portrait 2");
    
  return (
      
    <div className="FlowTalk grid cols-3">
      <section className="intro">
            <div className="holder-of-things">
                <p>Hello! Welcome to Flow</p>
                <h1>Talk to [brand name]</h1>
            </div>
          <div className="upload-box">
               <label id="upload">Upload brand guidelines [pdf]
               <input type="file" input="upload" accept="application/pdf" onChange={extractText} />
            </label>
          </div>
      </section>

      <section className="sidebar brand-summary">
            <ul className="padb" id="colors">
               {latestDoc ? (
                  <>
                    <li style={{ backgroundColor: latestDoc["Color-Primary-HEX"].split(';')[0] }}>
                        <span className="color-name" style={{ fontFamily: latestDoc["Main-Font"].split(';')[0] }} >{latestDoc["Color-Primary-Name"].split(';')[0]}</span>
                    </li>
                    <li style={{ backgroundColor: latestDoc["Color-Primary-HEX"].split(';')[1].trim() }}>
                      <span className="color-name" style={{ fontFamily: latestDoc["Main-Font"].split(';')[0] }} >{latestDoc["Color-Primary-Name"].split(';')[1]}</span>
                    </li>
                  </>
                ) : (
                  <li>Loading...</li>
                )}
            </ul>
            {latestDoc ? (
              <p style={{ fontFamily: latestDoc["Main-Font"].split(';')[0] }} id="company-mission">
                {latestDoc["Brand-Mission"]}
              </p>
            ) : (
              <p>Loading...</p>
            )}
            <img id="main-img" className="padb" src={bannerAsset ? bannerAsset.url : "https://placehold.co/600x300.png"} />
            <div className="grid cols-2 padb" id="images">
              <img src={squarePortrait1 ? squarePortrait1.url : "https://placehold.co/400x400.png"} />
              <img src={squarePortrait2 ? squarePortrait2.url : "https://placehold.co/400x400.png"} />
            </div>
            {latestDoc ? (
              <p style={{ fontFamily: latestDoc["Main-Font"].split(';')[0] }} >
                {latestDoc["Brand-Emotions"]}
              </p>
            ) : (
              <p>Loading...</p>
            )}
      </section>

      <section className="content">
        <div className="convo">
          {messages.filter(message => message.role !== "system").map((message, index) => (
            <div key={index}>
              <strong>{message.role}:</strong> {message.content}
            </div>
          ))}
          <div id="scroll-dummy" ref={dummy} />
        </div>
        {loading && <p className="loading"><div className="loader"></div></p>}
        {pdfText && <div className="pdf-content">
          <p>Extracting brand</p>
          <div className="loader"></div>
        </div>}
      </section>
      <section className="magic">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Type your message..."
            disabled={loading}
          />
          <button type="submit" disabled={loading}><span className="material-symbols-outlined">send</span></button>
        </form>
        <p className="tooltip"><span className="material-symbols-outlined">reminder</span>Tooltips here</p>
      </section>
    </div>
         
  );
};

export default Talk2Brand;