// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7fT14E1NUkC8ccL-Zp84oEzQhxrx9LjM",
  authDomain: "musa-flow.firebaseapp.com",
  projectId: "musa-flow",
  storageBucket: "musa-flow.appspot.com",
  messagingSenderId: "165652957911",
  appId: "1:165652957911:web:548d025235fc78577d3e92",
  measurementId: "G-PVBSQS6XDV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
export default app;
