import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Practice from '../components/practice'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Flow - Enterprise Platform for 360 Brand Consistency</title>
        <meta property="og:title" content="Flow - AI-Powered Creative Partner" />
      </Helmet>
      <section className="home-hero">
        <header data-thq="thq-navbar" className="home-navbar">
          <div className="home-left">
            <img alt="image" src="/Branding/flow-logo.png" className="home-image06"/>
            <nav className="home-links">
              <a href="##what-for" className="home-link">What is Flow for?</a>
              <a href="##what-do" className="home-link2">What does Flow do?</a>
            </nav>
          </div>
          <div data-thq="thq-navbar-btn-group" className="home-right">
            <a href="##contact" className="home-book button button-main">
              <span className="home-text06">Contact us</span>
            </a>
          </div>
        </header>
        <div className="home-main">
          <div className="home-content">
            <div className="home-heading07">
              <span className="home-text12">Let it FLOW</span>
                <h1 className="home-text13"><span className="home-text25">Enterprise Platform for</span> 360 Brand Consistency</h1>
              <p className="home-caption6">FLOW is an AI-Powered system that understands your brand and focuses making on-brand content and visual assets a breeze.</p>
            </div>
          </div>
        </div>
      </section>
      <section id="#what-for" className="home-features">
        <div className="home-section06">
          <div className="home-content1">
            <div className="home-header08">
              <h2 className="home-heading08">Gone are the days when maintaining <span className="home-text25"> brand consistency </span>required effort
              </h2>
              <p className="home-capton">
                Leave it to <span className="home-text25">FLOW</span>, we know you got more important things to get
                done.
              </p>
            </div>
          </div>
          <img alt="image" src="/presentation.png" className="home-image09" />
        </div>
        <div className="home-section07">
          <div className="home-content2">
            <div className="home-header09">
              <p className="home-heading09">How many employees it takes to prepare
                <span className="home-text25"> a presentation </span>for a new customer?
              </p>
              <p className="home-capton1">One. No joke.<br/>(Although we do love collaboration!)</p>
            </div>
          </div>
          <img
            alt="image"
            src="/examination-1500w.png"
            className="home-image10"
          />
        </div>
      </section>
      <section id="#what-do" className="home-practices1">
        <div className="home-heading13">
          <h2 className="home-text30">What does <span>FLOW</span> do?</h2>
        </div>
        <div className="home-content4">
          <div className="home-grid1">
            <p>
              <div className="home-practice-wrapper">
                <Practice title="Branding" description="ps. we fully know that your brand is more nuanced than just colors and fonts."></Practice>
              </div>
            </p>
            <p>
              <div className="home-practice-wrapper">
                <Practice
                  title="Recommendations"
                  description="Flow suggests brand assets based on prompts"
                ></Practice>
              </div>
            </p>
            <p>
              <div className="home-practice-wrapper">
                <Practice title="Generations" description="Text, images.. what modern AI can offer!"></Practice>
              </div>
            </p>
            <p>
              <div className="home-practice-wrapper">
                <Practice title="..and more" description="Coming up!"></Practice>
              </div>
            </p>
          </div>
        </div>
    </section>
    
    <section id="#join-us" className="home-features">
        <div className="home-section06">
          <div className="home-content1">
            <div className="home-header08">
             <p>We are hiring!</p>
              <h2 className="home-heading08"><span className="home-text25">Be part</span> of building a new world.
              </h2>
              <p className="home-capton">Beyond what we make, we also promise to treat you like the unique individual you are. It is time.
              </p>
              <ul>
                    <li>React Engineer (Front End)</li>
                    <li>UX</li>
                    <li>Marketing</li>
                    <li>Sales</li>
            </ul>
            <p className="home-text25">Contact us below!</p>
            </div>
          </div>
          <img alt="image" src="/be-you.png" className="home-image09" />
        </div>
    </section>
            <h2 className="home-text31">Join us at the start</h2>
            <p className="discover">Discover what’s coming before anyone else and be part of the extraordinary!</p>
    
        <iframe className="form" id="#contact" src="https://docs.google.com/forms/d/e/1FAIpQLSdGF3ORMW727AWtbTWbZKP08n944i4gphryUk5sDYn_Fv-CYw/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
   
      <div className="home-footer">
        <div className="home-left1">
          <div className="home-legal">
            <span className="home-copyright">
              © 2024 Flow. All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
      <div>
        <div className="home-container10">
          <Script
            html={`<script>
const dataLetters = document.querySelectorAll("[data-letter]");
let activeLetters = [];
const maxResults = 6;

dataLetters.forEach(letter => {
  letter.addEventListener("click", function() {
    if (this.classList.contains("letter-active")) {
      this.classList.remove("letter-active");
      activeLetters = activeLetters.filter(a => a !== this.dataset.letter);
    } else {
      this.classList.add("letter-active");
      activeLetters.push(this.dataset.letter);
    }
    if (activeLetters.length == 0) {
      document.querySelector("[data-teleport='results']").style.display = "none";
      return;
    }
    showResults();
  });
});

const showResults = () => {
  fetch("https://raw.githubusercontent.com/Shivanshu-Gupta/web-scrapers/master/medical_ner/medicinenet-diseases.json")
    .then(response => response.json())
    .then(data => {
      const filteredData = data.filter(item => {
        const firstLetter = item.disease.charAt(0).toLowerCase();
        if (activeLetters.includes(firstLetter)) {
          return true;
        }
        return false;
      });

      document.querySelector("[data-teleport='results']").style.display = "flex";
      const resultsContainer = document.querySelector("[data-results='letters']");
      resultsContainer.innerHTML = "";

      let counter = 0;
      const diseaseGroups = {};
      const totalActiveLetters = activeLetters.length;

      filteredData.forEach(disease => {
        const firstLetter = disease.disease[0].toLowerCase();
        if (diseaseGroups[firstLetter]) {
          diseaseGroups[firstLetter].push(disease);
        } else {
          diseaseGroups[firstLetter] = [disease];
        }
      });

      Object.keys(diseaseGroups).sort().forEach((firstLetter, index) => {
        const diseasesForThisLetter = diseaseGroups[firstLetter];
        const diseasesToShow = diseasesForThisLetter.slice(0, Math.ceil(maxResults / totalActiveLetters));

        diseasesToShow.forEach(disease => {
          const resultContainer = document.createElement("div");
          resultContainer.classList.add("search-result");
          resultContainer.classList.add("invisible");
          resultContainer.style.animationDelay = \`\${counter * 0.25}s\`;

          const resultText = document.createElement("span");
          resultText.classList.add("result-text");
          resultText.textContent = disease.disease;

          resultContainer.appendChild(resultText);
          resultsContainer.appendChild(resultContainer);
          counter++;

          if (counter === maxResults) {
            const moreContainer = document.createElement("div");
            moreContainer.classList.add("search-result");
            moreContainer.classList.add("more-results");

            const moreText = document.createElement("span");
            moreText.classList.add("result-text");
            moreText.textContent = "More";

            moreContainer.appendChild(moreText);
            resultsContainer.appendChild(moreContainer);
            addedMoreContainer = true;
            return;
          }
        });
      });
    });
};
</script>
`}
          ></Script>
        </div>
      </div>
      <div>
        <div className="home-container12">
          <Script
            html={`<script>
function scroll(direction) {
  const doctorsDiv = document.querySelector('[data-teleport="doctors"]');
  const scrollAmount = 300;
  if (direction === 'previous') {
    doctorsDiv.scrollBy({
      left: -scrollAmount,
      behavior: 'smooth'
    });
  } else if (direction === 'next') {
    doctorsDiv.scrollBy({
      left: scrollAmount,
      behavior: 'smooth'
    });
  }
}

const buttons = document.querySelectorAll('[data-doctors]');
buttons.forEach(button => {
  button.addEventListener('click', () => {
    const direction = button.dataset.doctors;
    scroll(direction);
  });
});
</script>`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

export default Home
